import * as React from 'react';
import { LayoutProvider, Wrapper } from '../components/layout';
import { CenteredContainer, ResponsiveContainer } from '../atoms/Container';
import { PostDetail } from '../components/sections';
import { useTableOrMobile } from '../hooks';
import { useCannonStyletron } from '../theme/CannonSafesTheme';
import IPage from '../@types/IPage';
import { IAllBlogs } from '../pages/blog';

const BlogArticle = (props: IPage<IAllBlogs>) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const data = props.pageContext;

  return (
    <LayoutProvider seo>
      <CenteredContainer>
        <ResponsiveContainer flexDirection='column'>
          <PostDetail
            title={data.title}
            mainMedia={data.mainMedia}
            blogCategory={data.blogCategory.Name}
            bodyText={data.bodyText}
            blogEntries={data.blogEntries}
          />
        </ResponsiveContainer>
      </CenteredContainer>
    </LayoutProvider>
  );
};

export default BlogArticle;
